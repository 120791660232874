import $ from 'jquery';

import ModuleNavigation from '../modules/moduleNavigation';
import CustomAnimation from '../animation/customAnimation';
import ModuleService from '../modules/moduleSerivce';

class ServicePage {
  init() {
    const moduleNavigation = new ModuleNavigation();
    moduleNavigation.init();

    const moduleService = new ModuleService();
    moduleService.init();

    setTimeout(function () {
      const customAnimation = new CustomAnimation();
      customAnimation.init();
    }, 500);
  }
}

export default ServicePage;
