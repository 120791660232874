import $ from 'jquery';

import ModuleCompany from '../modules/moduleCompany';
import ModuleNavigation from '../modules/moduleNavigation';
import CustomAnimation from '../animation/customAnimation';
import ModuleCareer from "../modules/moduleCareer";

class CareerPage {
  init() {
    const moduleNavigation = new ModuleNavigation();
    moduleNavigation.init();

    const moduleCareer = new ModuleCareer();
    moduleCareer.init();

    setTimeout(function () {
      const customAnimation = new CustomAnimation();
      customAnimation.init();
    }, 500);
  }
}

export default CareerPage;
