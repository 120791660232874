import { gsap } from 'gsap';
import { ScrollTrigger } from 'gsap/ScrollTrigger';

class CustomAnimation {
  init() {
    gsap.registerPlugin(ScrollTrigger);

    // company >> home
    gsap.to('#home', {
      scrollTrigger: {
        trigger: '#home',
        endTrigger: '#technology',
        start: 'top 0%',
        end: 'bottom 0%',
        markers: false,
        pin: true,
        pinSpacing: false,
        toggleActions: 'play reverse play reverse',
        scrub: 0
      },
      y: -200
    });

    gsap.to('.home_text_holder', {
      scrollTrigger: {
        trigger: '.home_text_holder',
        start: 'top 30%',
        end: 'top -100%',
        markers: false,
        scrub: 0
      },
      y: -50
    });

    // company >> technology
    gsap.to('.technology_text_content', {
      scrollTrigger: {
        trigger: '#technology',
        // start: "top bottom", // the default values
        // end: "bottom top",
        scrub: true
      },
      yPercent: 20,
      ease: 'none'
    });
    gsap.from('.technology_text_holder', {
      scrollTrigger: {
        trigger: '.technology_text_holder',
        start: 'top bottom', // the default values
        end: 'bottom 70%',
        scrub: 0.1,
        markers: false
      },
      xPercent: -5,
      ease: 'none'
    });
    gsap.to('.technology_quotation_holder_01', {
      scrollTrigger: {
        trigger: '#technology',
        // start: "top bottom", // the default values
        // end: "bottom top",
        scrub: true
      },
      yPercent: 50,
      xpercent: 10,
      ease: 'none'
    });

    // company >> manager
    this.animateTextLeft('.manager_headline_01');
    this.animateTextLeft('.manager_headline_02');
    this.animateTextLeft('.manager_copy');
    this.animateTextLeft('.manager_button');

    // company >> composite
    gsap.to('.row_composite', {
      scrollTrigger: {
        trigger: '.row_composite',
        // endTrigger: '#footer',
        start: 'top 100%',
        end: 'bottom 0%',
        markers: false,
        scrub: 0
      },
      yPercent: 30
    });

    // usp
    gsap.to('.usp_text_content', {
      scrollTrigger: {
        trigger: '#technology',
        // start: "top bottom", // the default values
        // end: "bottom top",
        scrub: 0
      },
      yPercent: 10,
      ease: 'none'
    });

    // advantage
    gsap.to('.advantage_text_content', {
      scrollTrigger: {
        trigger: '#technology',
        // start: "top bottom", // the default values
        // end: "bottom top",
        scrub: 0
      },
      yPercent: 10,
      ease: 'none'
    });

    // teamansprechpartner
    this.animateBackgroundImage('#portfolio', '#portfolio');
    this.animateBackgroundImage('#careeradvantages', '#careeradvantages');
    this.animateBackgroundImage('#careerfooter', '#careerfooter');
    this.animateBackgroundImage('#teamansprechpartner', '#teamansprechpartner');
    this.animateBackgroundImage('#teamfachbereiche', '#teamfachbereiche');
    this.animateBackgroundImage('#teambetrieb', '#teambetrieb');
  }

  animateTextLeft(element) {
    gsap.from(element, {
      scrollTrigger: {
        trigger: element,
        start: 'top 130%',
        end: 'top 80%',
        markers: false,
        scrub: 0.4
      },
      y: 50
    });
  }

  animateBackgroundImage(element, triggerElement) {
    gsap.from(element, {
      scrollTrigger: {
        trigger: triggerElement,
        start: 'top 100%',
        end: 'top -20%',
        markers: false,
        scrub: 0.1
      },
      y: -200
    });
  }

  animateBackgroundImage2(element, triggerElement) {
    gsap.from(element, {
      scrollTrigger: {
        trigger: triggerElement,
        start: 'top 100%',
        end: 'top -20%',
        markers: false,
        scrub: 0.1
      },
      y: -100
    });
  }
}

export default CustomAnimation;
