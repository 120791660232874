import $ from 'jquery';
import 'is-in-viewport';

let footerTimeout;

function checkViewport() {
  $('.page_indicator_text').html('');
  $('#page_indicator').removeClass('page_indicator_show');

  if ($('#usp').is(':in-viewport')) {
    $('.page_indicator_text').html($('#usp').data('indicator'));
    $('#page_indicator').addClass('page_indicator_show');
  }
  if ($('#portfolio').is(':in-viewport')) {
    $('.page_indicator_text').html($('#portfolio').data('indicator'));
    $('#page_indicator').addClass('page_indicator_show');
  }

  if ($('#footer_spacer').is(':in-viewport')) {
    $('.page_indicator_text').html('');
    $('#page_indicator').removeClass('page_indicator_show');
  }
}

class ModuleService {
  init() {
    this.animate();
    this.onResize();
    this.onScroll();
  }

  animate = () => {
    setTimeout(function () {
      // $('#header').addClass('show');
    }, 500);

    setTimeout(function () {
      // $('.header_headline').addClass('show');
    }, 1000);

    setTimeout(function () {
      // $('.header_airbnb_logo').addClass('show');
    }, 2500);
  };

  onResize() {
    $(window)
      .resize(function () {
        const winWidth = $(window).width();
        let uspHeight;

        if (winWidth > 752) {
          uspHeight = winWidth / (2560 / 3290);
          uspHeight = winWidth / (2560 / 3539);
        } else {
          uspHeight = winWidth / (1500 / 4800);
        }

        $('#usp').css('height', `${uspHeight}px`);

        // footer
        clearTimeout(footerTimeout);
        footerTimeout = setTimeout(function () {
          const footerHeight = $('#footer').height();
          $('#footer_spacer').css('height', `${footerHeight}px`);
        }, 350);
      })
      .resize();
  }

  onScroll() {
    $(window)
      .scroll(function () {
        checkViewport();
      })
      .scroll();
  }
}

export default ModuleService;
