import 'bootstrap/dist/js/bootstrap';
import $ from 'jquery';
import ModulePreloader from './modules/modulePreloader';
import Companypage from './pages/companypage';
import Servicepage from './pages/servicepage';
import TeamPage from './pages/teampage';
import CareerPage from './pages/careerpage';
import ModuleForm from './modules/moduleForm';

const pageName = $('body').data('page');
let page;

$(document).ready(function () {
  const modulePreloader = new ModulePreloader();
  modulePreloader.init();

  if (pageName === 'company') {
    page = new Companypage();
    page.init();
  } else if (pageName === 'service') {
    page = new Servicepage();
    page.init();
  } else if (pageName === 'team') {
    page = new TeamPage();
    page.init();
  } else if (pageName === 'career') {
    page = new CareerPage();
    page.init();
  }
  const moduleForm = new ModuleForm();
  moduleForm.init();
});
