import $ from 'jquery';

import ModuleCompany from '../modules/moduleCompany';
import ModuleNavigation from '../modules/moduleNavigation';
import CustomAnimation from '../animation/customAnimation';
import ModuleTeam from "../modules/moduleTeam";

class TeamPage {
  init() {
    const moduleNavigation = new ModuleNavigation();
    moduleNavigation.init();

    const moduleTeam = new ModuleTeam();
    moduleTeam.init();

    setTimeout(function () {
      const customAnimation = new CustomAnimation();
      customAnimation.init();
    }, 500);
  }
}

export default TeamPage;
