import $ from 'jquery';

import ModuleNavigation from '../modules/moduleNavigation';
import CustomAnimation from '../animation/customAnimation';
import ModuleCompany from '../modules/moduleCompany';

class Companypage {
  init() {
    const moduleNavigation = new ModuleNavigation();
    moduleNavigation.init();

    const moduleCompany = new ModuleCompany();
    moduleCompany.init();

    setTimeout(function () {
      const customAnimation = new CustomAnimation();
      customAnimation.init();
    }, 500);
  }
}

export default Companypage;
