import $ from 'jquery';
import validate from 'jquery-validation';
import 'friendly-challenge/widget';

class ModuleForm {
  init() {
    this.initListener();
    this.initForm();
  }

  initListener() {
    $('.form_policy_button').click(function () {
      $('#policyModal').addClass('show');
    });

    $('.modal_close_button').click(function () {
      $('#policyModal').removeClass('show');
    });
  }

  initForm() {
    this.resetForm();
    const formMessages = $('#form-messages');

    $("form[name='registration']").validate({
      rules: {
        firstname: 'required',
        lastname: 'required',
        email: {
          required: true,
          email: true
        },
        nachricht: 'required',
        policy: 'required'
      },
      messages: {
        name: 'Bitte geben Sie Ihren Namen ein.',
        firma: 'Bitte geben Sie Ihren Firma ein.',
        phone: 'Bitte geben Sie Ihre Telefon-Nummer ein.',
        nachricht: 'Bitte geben Sie Ihre Nachricht ein.',
        policy: 'Bitte akzeptieren Sie die Bedingungen.',
        email: 'Bitte geben Sie Ihre korrekte E-Mail ein.'
      },
      submitHandler(form) {
        // form.submit();

        const formData = $(form).serialize();

        $.ajax({
          type: 'POST',
          url: '../contact_form.php',
          data: formData
        })
          .done(function (response) {
            // alert('OK');

            $('#firstname').val('');
            $('#lastname').val('');
            $('#email').val('');
            $('#nachricht').val('');
            $('#policy').prop('checked', false);

            // Set the message text.
            formMessages.text(response);
          })
          .fail(function (data) {
            // alert('FAIL');

            // Set the message text.
            if (data.responseText !== '') {
              console.log(data.responseText);
              formMessages.text(data.responseText);
            } else {
              console.log('ERROR');
              formMessages.text('Oops! An error occured and your message could not be sent.');
            }
            // alert('Send FAIL');
          });
      }
    });
  }

  resetForm() {
    $('#firstname').val('');
    $('#lastname').val('');
    $('#company').val('');
    $('#message').val('');
    $('#email').val('');
    $('#policy').prop('checked', false);
  }
}

export default ModuleForm;
