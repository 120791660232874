import $ from 'jquery';

const IS_TOUCH_DEVICE = 'ontouchstart' in document.documentElement;
const CONTENT = $('body').data('page');

function hideMobileNavi() {
  $('#site-nav').removeClass('show');
}

function checkMainNavigation() {
  if (CONTENT !== 'company') {
    return;
  }

  // ---------------------
  // show burger menu
  const scrollTop = $(window).scrollTop();
  const offsetTop = $('#home').height() - scrollTop;

  // ipad quer
  if ($(window).width() < 992) {
    $('.navbar').removeClass('intro');
  } else if (offsetTop > 0) {
    $('.navbar').addClass('intro');
  } else {
    $('.navbar').removeClass('intro');
  }
}

class ModuleNavigation {
  init() {
    this.initListener();
    this.onResize();
    this.onScroll();
  }

  initListener = () => {
    $('.nav-link').mouseover(function (e) {
      e.preventDefault();

      if (IS_TOUCH_DEVICE === false) {
        const highlight = $(this).parent().hasClass('highlight');
        if ($(this).data('index') < 5 && highlight === false) {
          $(this).next('.rollover_image').css('margin-top', '0px');
        }
      }
    });

    $('.nav-link').mouseout(function (e) {
      e.preventDefault();

      if (IS_TOUCH_DEVICE === false) {
        const highlight = $(this).parent().hasClass('highlight');
        if ($(this).data('index') < 6 && highlight === false) {
          $(this).next('.rollover_image').css('margin-top', '10px');
        }
      }
    });

    $('.nav-link').click(function (e) {
      const index = $(this).data('index');

      hideMobileNavi();

      // if contact
      if (index === 4) {
        e.preventDefault();
      }
    });

    $('.private_policy_link').click(function(e) {

      $('.text_holder_policy').animate({ scrollTop: 0 }, '1000');

      const selectedId = $(this).data('id');
      const panel = $(`.private_policy_content_${  selectedId}`);
      const arrow = $('.icon', $(this));
      if (panel.css('maxHeight') !== '0px') {
        panel.css('maxHeight', '0');
        panel.removeClass('show');
        arrow.removeClass('rotate');
      } else {
        panel.css('maxHeight', panel.prop('scrollHeight') + 'px');
        panel.addClass('show');
        arrow.addClass('rotate');
      }

      $('.private_policy_content').each(function (index) {
        if (index !== selectedId) {
          $(this).css('maxHeight', '0');
          $(this).removeClass('show');
        }
      });

      $('.icon').each(function(index)
      {
        if (index !== selectedId) {
          $(this).removeClass('rotate');
        }
      });
    });
  };

  onResize = () => {
    $(window)
      .resize(function () {
        checkMainNavigation();
        hideMobileNavi();
      })
      .resize();
  };

  onScroll = () => {
    $(window)
      .scroll(function () {
        checkMainNavigation();
        hideMobileNavi();
      })
      .scroll();
  };
}

export default ModuleNavigation;
