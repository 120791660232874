import $ from 'jquery';
import 'is-in-viewport';

const IS_TOUCH_DEVICE = 'ontouchstart' in document.documentElement;
let yearIndex = 0;
const yearCount = 5;
let footerTimeout;

function moveHighlightLine() {
  const steps = yearCount * 2;
  const stepsWidth = $(window).width() / steps;
  const highlightLineWidth = stepsWidth + yearIndex * 2 * stepsWidth;

  $('.timeline_baseline_highlight').css('width', `${highlightLineWidth}px`);
}
function showYearContentByIndex() {
  for (let i = 0; i < yearCount; i += 1) {
    const yearContent = $(`.year_content_${i}`);
    const timelineMarker = $(`.timeline_marker_${i}`);

    if (i === yearIndex) {
      /* yearContent.css("display", "block"); */
      yearContent.fadeIn();

      timelineMarker.addClass('active');
    } else {
      /* yearContent.css("display", "none"); */
      yearContent.fadeOut();

      timelineMarker.removeClass('active');
    }
  }

  moveHighlightLine();
}

function checkViewport() {
  $('.page_indicator_text').html('');
  $('#page_indicator').removeClass('page_indicator_show');

  if ($('.technology_text_holder').is(':in-viewport')) {
    $('.page_indicator_text').html($('#technology').data('indicator'));
    $('#page_indicator').addClass('page_indicator_show');
  }
  if ($('#manager').is(':in-viewport')) {
    $('.page_indicator_text').html($('#manager').data('indicator'));
    $('#page_indicator').addClass('page_indicator_show');
  }
  if ($('.row_composite').is(':in-viewport')) {
    $('.page_indicator_text').html($('#history').data('indicator'));
    $('#page_indicator').addClass('page_indicator_show');
  }
  if ($('#footer_spacer').is(':in-viewport')) {
    $('.page_indicator_text').html('');
    $('#page_indicator').removeClass('page_indicator_show');
  }
}

class ModuleCompany {
  init() {
    this.initListener();
    this.animate();
    this.onResize();
    this.onScroll();
  }

  initListener() {
    $('.timeline_marker').click(function (e) {
      e.preventDefault();

      yearIndex = $(this).data('index');

      showYearContentByIndex();
    });

    $('.year_button_next').mouseover(function () {
      // $('.year_button_next_img').attr('src', 'images/history_button_right_big.jpg');
    });
    $('.year_button_next').mouseout(function () {
      if (IS_TOUCH_DEVICE === false) {
        // $('.year_button_next_img').attr('src', 'images/history_button_right.jpg');
      }
    });
    $('.year_button_next').click(function (e) {
      e.preventDefault();

      // timelineClicked = true;

      yearIndex += 1;

      if (yearIndex > yearCount - 1) {
        yearIndex = 0;
      }

      showYearContentByIndex();
    });

    $('.year_button_prev').mouseover(function () {
      // $('.year_button_prev_img').attr('src', 'images/history/history_button_left_big.jpg');
    });
    $('.year_button_prev').mouseout(function () {
      if (IS_TOUCH_DEVICE === false) {
        // $('.year_button_prev_img').attr('src', 'images/history/history_button_left.jpg');
      }
    });
    $('.year_button_prev').click(function (e) {
      e.preventDefault();

      // timelineClicked = true;

      yearIndex -= 1;

      if (yearIndex < 0) {
        yearIndex = yearCount - 1;
      }

      showYearContentByIndex();
    });
  }

  animate = () => {
    setTimeout(function () {
      // $('#header').addClass('show');
    }, 500);

    setTimeout(function () {
      // $('.header_headline').addClass('show');
    }, 1000);

    setTimeout(function () {
      // $('.header_airbnb_logo').addClass('show');
    }, 2500);
  };

  onResize() {
    $(window)
      .resize(function () {
        const winWidth = $(window).width();
        let technologyHeight;
        let managerHeight;
        let historyHeight;

        if (winWidth > 752) {
          technologyHeight = winWidth / (2560 / 3328);
          managerHeight = winWidth / (2560 / 2500);
          historyHeight = winWidth / (2560 / 2295);
        } else {
          technologyHeight = winWidth / (750 / 1909);
          managerHeight = winWidth / (750 / 1080);
          historyHeight = winWidth / (1500 / 3300);
        }

        $('#technology').css('height', `${technologyHeight}px`);
        $('#manager').css('height', `${managerHeight}px`);
        $('#history').css('height', `${historyHeight}px`);

        // footer
        clearTimeout(footerTimeout);
        footerTimeout = setTimeout(function () {
          const footerHeight = $('#footer').height();
          $('#footer_spacer').css('height', `${footerHeight}px`);
        }, 350);

        moveHighlightLine();
      })
      .resize();
  }

  onScroll() {
    $(window)
      .scroll(function () {
        checkViewport();
      })
      .scroll();
  }
}

export default ModuleCompany;
