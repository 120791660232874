import $ from 'jquery';
import 'is-in-viewport';

let footerTimeout;

function showTeamAnsprechpartnerFotos() {
  $('.teamansprechpartner_button').fadeOut('500');
  $('#teamansprechpartner_detail').slideDown('500');
  $('html, body').animate(
    {
      scrollTop: $('#teamansprechpartner_detail').offset().top - 50
    },
    500
  );
}
function hideTeamAnsprechpartnerFotos() {
  $('.teamansprechpartner_button').fadeIn();
  $('#teamansprechpartner_detail').slideUp('500');
  $('html, body').animate(
    {
      scrollTop: $('#teamansprechpartner').offset().top
    },
    500
  );
}

function showTeamFachbereicheDetails() {
  $('.teamfachbereiche_button').fadeOut();
  $('#teamfachbereiche_details').slideDown('500');
  $('html, body').animate(
    {
      scrollTop: $('#teamfachbereiche_details').offset().top
    },
    500
  );
}
function hideTeamFachbereicheDetails() {
  $('.teamfachbereiche_button').fadeIn();
  $('#teamfachbereiche_details').slideUp('500');
  $('html, body').animate(
    {
      scrollTop: $('#teamfachbereiche').offset().top
    },
    500
  );
}

function checkViewport() {
  $('.page_indicator_text').html('');
  $('#page_indicator').removeClass('page_indicator_show');

  if ($('#teamansprechpartner').is(':in-viewport')) {
    $('.page_indicator_text').html($('#teamansprechpartner').data('indicator'));
    $('#page_indicator').addClass('page_indicator_show');
  }
  if ($('#teamfachbereiche').is(':in-viewport')) {
    $('.page_indicator_text').html($('#teamfachbereiche').data('indicator'));
    $('#page_indicator').addClass('page_indicator_show');
  }
  if ($('#teambetrieb').is(':in-viewport')) {
    $('.page_indicator_text').html($('#teambetrieb').data('indicator'));
    $('#page_indicator').addClass('page_indicator_show');
  }
  if ($('#footer_spacer').is(':in-viewport')) {
    $('.page_indicator_text').html('');
    $('#page_indicator').removeClass('page_indicator_show');
  }
}

class ModuleTeam {
  init() {
    this.initListener();
    this.onResize();
    this.onScroll();
  }

  initListener() {
    // teamansprechpartner
    $('.teamansprechpartner_button').click(function () {
      showTeamAnsprechpartnerFotos();
    });
    $('.teamansprechpartner_detail_button').click(function () {
      hideTeamAnsprechpartnerFotos();
    });

    // fachbereiche
    $('.teamfachbereiche_button').click(function () {
      showTeamFachbereicheDetails();
    });
    $('.teamfachbereiche_details_button').click(function () {
      hideTeamFachbereicheDetails();
    });
  }

  onResize() {
    $(window)
      .resize(function () {
        const winWidth = $(window).width();
        let teamHomeHeight;
        let teamAnsprechpartnerHeight;
        let teamAnsprechpartnerOffset;
        let teamFachbereicheHeight;
        let teamFachbereicheDetailHeight;
        let teamBetriebHeight;
        let teamFooterHeight;
        let teamFooterOffset;

        if (winWidth > 752) {
          teamHomeHeight = winWidth / (2560 / 1592);
          teamAnsprechpartnerHeight = winWidth / (2560 / 2100);
          teamAnsprechpartnerOffset = winWidth * 0.2;
          teamFachbereicheHeight = winWidth / (2560 / 1838);
          teamFachbereicheDetailHeight = winWidth / (2560 / 1762);
          teamBetriebHeight = winWidth / (2560 / 1862);
          teamFooterHeight = winWidth / (2560 / 480);
          teamFooterOffset = winWidth * 0.1;
        } else {
          teamHomeHeight = winWidth / (1500 / 2362);
          teamAnsprechpartnerHeight = winWidth / (1500 / 1684);
          teamAnsprechpartnerOffset = winWidth * 0.1;
          teamFachbereicheHeight = winWidth / (1500 / 1463);
          teamFachbereicheDetailHeight = winWidth / (1500 / 1170);
          teamBetriebHeight = winWidth / (1500 / 1480);
          // teamBetriebHeight = winWidth / (1500 / 1790);
          teamFooterHeight = winWidth / (1500 / 396);
          teamFooterOffset = winWidth * 0.18;
        }

        $('#team').css('height', `${teamHomeHeight}px`);
        $('#teamansprechpartner').css('height', `${teamAnsprechpartnerHeight}px`);
        $('#teamansprechpartner').css('margin-top', `${-teamAnsprechpartnerOffset}px`);
        $('#teamfachbereiche').css('height', `${teamFachbereicheHeight}px`);
        $('#teamfachbereiche_details').css('height', `${teamFachbereicheDetailHeight}px`);
        $('#teambetrieb').css('height', `${teamBetriebHeight}px`);
        $('#teamfooter').css('height', `${teamFooterHeight}px`);
        $('#teamfooter').css('margin-top', `${-teamFooterOffset}px`);

        // footer
        clearTimeout(footerTimeout);
        footerTimeout = setTimeout(function () {
          const footerHeight = $('#footer').height();
          $('#footer_spacer').css('height', `${footerHeight}px`);
        }, 350);
      })
      .resize();
  }

  onScroll() {
    $(window)
      .scroll(function () {
        checkViewport();
      })
      .scroll();
  }
}

export default ModuleTeam;
