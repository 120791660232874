import $ from 'jquery';

const preloaderContainer = $('.preloader_container');
const preloader = $('.preloader');

class ModulePreloader {
  init() {
    preloaderContainer.addClass('hide');
    preloader.delay(500).fadeOut(1000);
  }
}

export default ModulePreloader;
