import $ from 'jquery';
import 'is-in-viewport';

let footerTimeout;

function checkViewport() {
  $('.page_indicator_text').html('');
  $('#page_indicator').removeClass('page_indicator_show');

  if ($('#career').is(':in-viewport')) {
    $('.page_indicator_text').html($('#career').data('indicator'));
    $('#page_indicator').addClass('page_indicator_show');
  }
  if ($('#careeradvantages').is(':in-viewport')) {
    $('.page_indicator_text').html($('#careeradvantages').data('indicator'));
    $('#page_indicator').addClass('page_indicator_show');
  }
  if ($('#careerfooter').is(':in-viewport')) {
    $('.page_indicator_text').html($('#careerfooter').data('indicator'));
    $('#page_indicator').addClass('page_indicator_show');
  }
  if ($('#footer_spacer').is(':in-viewport')) {
    $('.page_indicator_text').html('');
    $('#page_indicator').removeClass('page_indicator_show');
  }
}

class moduleCareer {
  init() {
    this.onResize();
    this.onScroll();
  }

  onResize() {
    $(window)
      .resize(function () {
        const winWidth = $(window).width();
        let careerHomeHeight;
        let careerAdvantagesHomeHeight;
        let careerAdvantagesHomeOffset;
        let careerFooterHomeHeight;
        let careerFooterHomeOffset;

        if (winWidth > 752) {
          careerHomeHeight = winWidth / (2560 / 1592);
          careerAdvantagesHomeHeight = winWidth / (2560 / 1620);
          careerAdvantagesHomeOffset = winWidth * 0.2;
          careerFooterHomeHeight = winWidth / (2560 / 973);
          careerFooterHomeOffset = winWidth * 0.1;
        } else {
          careerHomeHeight = winWidth / (1500 / 2191);
          careerAdvantagesHomeHeight = winWidth / (1500 / 2200);
          careerAdvantagesHomeOffset = winWidth * 0.18;
          careerFooterHomeHeight = winWidth / (1500 / 1250);
          careerFooterHomeOffset = winWidth * 0.18;
        }

        $('#career').css('height', `${careerHomeHeight}px`);
        $('#careeradvantages').css('height', `${careerAdvantagesHomeHeight}px`);
        $('#careeradvantages').css('margin-top', `${-careerAdvantagesHomeOffset}px`);
        $('#careerfooter').css('margin-top', `${-careerFooterHomeHeight}px`);
        $('#careerfooter').css('margin-top', `${-careerFooterHomeOffset}px`);

        // footer
        clearTimeout(footerTimeout);
        footerTimeout = setTimeout(function () {
          const footerHeight = $('#footer').height();
          $('#footer_spacer').css('height', `${footerHeight}px`);
        }, 350);
      })
      .resize();
  }

  onScroll() {
    $(window)
      .scroll(function () {
        checkViewport();
      })
      .scroll();
  }
}

export default moduleCareer;
